* {
	color: black !important;
	display: block !important;
	float: none !important;
	border: 0 !important;
	width: 100% !important;
	max-width: 100% !important;
	text-shadow: none !important;
	margin: 0 !important;
	padding:0 !important;
	height: auto !important;
	background-color: white !important; 
	text-align: left !important;
	clear: both !important;
	transform: none !important;
}
img, section:before, section:after, footer, .full-width-img, header, .white-popup span, .owl-item.cloned { 
	display: none !important;
	background: none !important;
	 }
.start-hero, .footer-hero {
	background: none !important;
}
p, h1, h2 {
	margin: 1em 0 1em 0 !important;
}
strong { display: inline !important; }

html {
	font-size: 60% !important;
}